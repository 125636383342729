.react-select__option {
    display: block;
    font-size: 15px;
    color: red;
    padding: '0px';
    margin: '0px';
    align-items: 'center';
    padding: 11px 12px;
    width: 100%;
    user-select: none;
    box-sizing: border-box;
}
.react-select__option:hover {
    background-color: #ebecf0;
}
.react-select__option_selected {
    background-color: #42526e;
    color: #fff;
}
.react-select__option_selected:hover {
    background-color: #42526e;
}
