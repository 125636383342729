.ilink { 
    text-decoration: none !important;
    color: #1890ff;

    outline: none !important;
    outline-offset: none !important;
}

.sframe { 
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.iframe-container { 
position: relative;
overflow: hidden;
width: 100%;
padding-top: 56.25%;
}