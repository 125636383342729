.AnyClassForContainer {
  position: fixed;
  right: -200px;
  bottom: 1%;
  transition: right 0.5s;
  cursor: pointer;
  color: #ffffff;
  background-color: #33a0ff;
  font-size: 13px;
  padding: 4px 12px;
  border: none;
  border-radius: 3px;
  outline: none;

  :hover {
    filter: brightness(110%);
  }
  :active {
    filter: brightness(90%);
  }
}

.AnyClassForTransition {
  right: 1%;
}
