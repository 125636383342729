@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Spectral+SC&display=swap');
@import url('https://fonts.googleapis.com/css?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cinzel&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100%;
}

body {
  min-height: 100%;
  padding-bottom: 48px;
  font-family: 'IBM Plex Sans', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
}
